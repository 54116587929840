



































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import { RawLocation } from 'vue-router'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { isReadonly } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'CarModelForm',
  components: {
    CancelUpdateDialog: () => import('../../../components/cancel-update-dialog.vue'),
    SelectCarBrand: () => import('@/components/molecules/select-search/carBrand.vue'),
    CarEnginesList: () => import('@/components/molecules/carModel/carEnginesList.vue'),
    CarFinishesList: () => import('@/components/molecules/carModel/carFinishesList.vue'),
    TopBar,
  },
  data () {
    return {
      dialogDocument: false,
      showChangePassword: false,
      createEditCarModelDialog: false,
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    ...mapFields('carModelForm', [
      'carModel.model',
      'carModel.carBrand',
      'carModel.carEngines',
      'carModel.carFinishes',
    ]),
    ...mapState('carModelForm', {
      carModel: 'carModel',
      isCarModelSubmitting: 'isSubmitting',
      isFormChanged: 'isChanged',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
  },
  async created () {
    await this.init()
  },
  watch: {
    $route () {
      this.init()
    },
  },
  methods: {
    ...mapActions('carModelForm', {
      save: 'save',
      reset: 'reset',
      loadCarModelById: 'loadById',
      resetCarModel: 'reset',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    async init () {
      await this.resetCarModel()

      const idCarModel = this.$route.params.idCarModel
      if (idCarModel) {
        await this.loadCarModelById(idCarModel)
      }
    },
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(this.$refs.validationObserver)
      if (isValidatedForm) {
        await this.save()
        await this.sendSuccessMessage('car_model.form.saved')
        this.returnPrevious()
      }
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'CarModel List' })
      }
    },
  },
})
